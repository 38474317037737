import React from 'react'

function ClientSection() {
    return (
        <section class="client-section ptb-100">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-8">
                        <div class="section-heading text-center mb-5 font-card">
                            <h2>Tedarikçilerimiz </h2>
                            <p class="lead">Ming Market Tedarikçilerimiz</p>
                        </div>
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="owl-carousel owl-theme clients-carousel dot-indicator">
                            <div class="item single-client">
                                <img src="/img/avansas.webp" width="103" height="28" alt="müşteri logo avansas"
                                    class="client-img" />
                            </div>
                            <div class="item single-client">
                                <img src="/img/yorglass_logo.webp" width="103" height="27" alt="müşteri logo yorglass"
                                    class="client-img" />
                            </div>
                            <div class="item single-client">
                                <img src="/img/trendyol.webp" width="104" height="34" alt="müşteri logo trendyol"
                                    class="client-img" />
                            </div>
                            <div class="item single-client">
                                <img src="/img/storyel.webp" width="75" height="47" style={{ width: "75px" }}
                                    alt="müşteri logo storyel" class="client-img" />
                            </div>
                            <div class="item single-client">
                                <img src="/img/multinet.webp" width="103" height="23" alt="müşteri logo multinet"
                                    class="client-img" />
                            </div>
                            <div class="item single-client">
                                <img src="/img/hepsiBurada.webp" width="103" height="17" alt="müşteri logo hepsiBurada"
                                    class="client-img" />
                            </div>
                            <div class="item single-client">
                                <img src="/img/cicekSepeti.webp" width="103" height="20" alt="müşteri logo cicekSepeti"
                                    class="client-img" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ClientSection