import React from 'react'

function Platform() {
    return (
        <div class="main">
            <section class="hero-section ptb-100 background-img"
                style={{ background: "url('img/hero_bg_2.webp')no-repeat center center / cover" }}>
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-md-8 col-lg-7">
                            <div class="page-header-content text-white text-center pt-sm-5 pt-md-5 pt-lg-0">
                                <h1 id="platform" style={{ color: "white" }} class="post-title">Ming Platform Kullanım Kuralları</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div class="module ptb-100">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <article class="post">
                                <div class="post-wrapper">
                                    <div class="post-content">
                                        <p>Ming, oyunlaştırmanın dinamiklerini kullanarak uygulama içi aksiyonlardan puan kazandıran, kazanılan puanları markette ödüle dönüştüren, çalışanların birbiriyle ve çalıştıkları kurumla arasındaki etkileşimi artırmayı hedefleyen kurum için sosyal bir platformdur. <em>(Bundan sonra “<strong>Platform</strong>” olarak adlandırılacaktır.)</em>. Ming’in sunduğu hizmetlere ilişkin, bireysel olarak veya şirketler ile Hizmet ve İşbirliği Sözleşmesi imzalanmakta ve bu sözleşmenin tarafları Ming ve Müşteri olmaktadır. Uygulama ise Müşteri Çalışanları olarak yani Ming App Kullanıcıları olarak sizlere sağlanmaktadır.</p>

                                        <p>Ming App içerisinde işveren Müşteri’nin ve çalışma arkadaşlarınızın paylaşımlarını takip edebilir ve paylaşım yapabilirsiniz, hızlı anketler ile fikirlerinizi hızlıca belirtebilir, daha iyi bir çalışan deneyimi geliştirilmesine katkıda bulunabilirsiniz. Sektörel ve çeşitli konularda yayınlanacak quizlerlerle yarışarak puan kazanma şansı yakalayabilir, kurum içinde yayınlanan kariyer fırsatlarına başvurabilir, çevrenizde bu işe uygun olduğunu düşündüklerinizle paylaşabilirsiniz. İhtiyacınız olmayan eşyalarınıza platform aracılığıyla alıcı bulabilir sürdürülebilirliğe katkıda bulanabilirsiniz. Tüm lokasyonlarda çalışan saha ve ofis çalışanlarına tek uygulama üzerinden erişebilir, kurum hedefleri anlık takip edilebilecek görevlerim modülü ile günlük işlerinizden puan kazanabilir ve kazandığınız tüm puanları markette kendi seçeceğiniz ödüle dönüştürebilirsiniz. </p>

                                        <p>Çalışma arkadaşlarınız ile iş ve sosyal ortamın verimli ve keyifli bir şekilde devam edebilmesi ve Platform’u Ming App Kullanıcılarına daha iyi şartlarda sağlayabilmek adına işbu Ming Platform Kullanım Kuralları oluşturulmuştur. MİNG yalnızca uygulamaya ilişkin altyapıyı sağlamakta olup uygulamada oluşturulan ve paylaşılan bilgi ve belgelere ilişkin herhangi bir sorumluluğu bulunmamaktadır. Platform’da oluşturulan belgelerin veya uygulamaya yüklenen bilgi ve içeriklere ve bu sebeple bir hak ihlaline sebep olunmayacağına ilişkin sorumluluk Ming App Kullanıcılarına ve sizlerin çalışmakta olduğu işyeri yani Müşteri’ye aittir.</p>

                                        <p>Ming olarak yenilikçi ve eşitlikçi bir vizyona sahibiz. Bu sebeple Platform’da nefret söylemi, cinsiyetçi ve hakaret içerikli paylaşım ve içeriklerin yer alamaması konusunda oldukça dikkatli olmaya çalışıyoruz ve aşağıdaki kurallara uyarak Ming App Kullanıcılarının da aynı duyarlılığı göstermesini talep ediyoruz. Müşteri ve belirleyeceği yetkili personeller (Admin, proje yetkilisi vb.) ile Müşteri Çalışanları yani Ming App Kullanıcıları; </p>

                                        <ol>

                                            <li>Platform’u kullanırken hukuka, dürüstlük kurallarına riayet ederek iyi niyetli hareket edeceklerdir.  </li>

                                            <li>Platform’daki başta sosyal medya işlevini gören paylaşım alanlarında ve diğer tüm alanlar dahil olmak üzere, herhangi bir şekilde; başkalarının kişilik haklarını ihlal edici nitelikte onur kırıcı, argo/küfür, suç ve suçu özendirici, cinsel içerikli, tacizvari, radikal siyasi vb. söylemler ve bu minvalde yazılı, işitsel ve/veya görsel paylaşımlarda bulunmayacaklarını, bulunmaları halinde Ming ve/veya Müşteri’nin her türlü hukuki ve cezai hakkını kullanacağını ve paylaşımların kaldırılabileceğini, ilgili hesapların dondurulabilecek, askıya alınabilecek veya kapatılabileceğini,</li>

                                            <li>Ming veya Müşteri hakkında aşağılayıcı, küçük düşürücü, gizlilik ihlali niteliğinde, haksız rekabete konu olabilecek nitelikteki her türlü yazılı, işitsel ve/veya görsel paylaşımlarda bulunmayacaklarını, bulunmaları halinde Ming ve/veya Müşteri’nin her türlü hukuki ve cezai hakkını kullanacağını ve paylaşımların kaldırılabileceğini, ilgili hesapların dondurulabilecek, askıya alınabilecek veya kapatılabileceğini,</li>

                                            <li>Platform kullanımının her aşamasında, Kişisel Verilerin Korunması Mevzuatına uygun hareket edileceğini, aksi halde Ming’in veri işleyen veya herhangi bir sıfat ile ihlal oluşturan eylemden sorumlu olmayacağını ve bu ihlal nedeniyle herhangi bir idari, hukuki, cezai yaptırımının söz konusu olması halinden bundan da sorumlu olmayacağını, sonuç olarak Ming’in böylesi bir ihlal sebebiyle zarar görmesi halinde zararın Müşteri ve/veya Müşteri Çalışanı yani Ming App Kullanıcılarına rücu edilebileceğini,</li>

                                            <li>Müşteri Çalışanları’nın yararlanması için hizmete sunulan mağaza vb. alanlara yalnızca altyapı desteği sağlandığı ve buradaki her türlü alım-satım işleminden, işlemi yapan hesap sahibi Müşteri Çalışanı’nın sorumlu olduğunu, herhangi bir tazminat veya talebin muhatabının Ming olmadığını,</li>

                                            <li>Platform’daki mağaza vb. satış yapılabilecek alanlarda satışı yasak eşyaların (tehlikeli, yasadışı veya cinsel, siyasal içerikli vb. ürünler) konulmayacağını, bunlara ilişkin ilan veya paylaşımların yapılması halinde Ming ve/veya Müşteri’nin her türlü hukuki ve cezai hakkını kullanacağını ve paylaşımların kaldırılabileceğini, ilgili hesapların dondurulabilecek, askıya alınabilecek veya kapatılabileceğini,</li>

                                            <li>Platform içerisindeki görev, paylaşım vb. şekilde toplanan puanların kasıtlı olarak manipüle edilmeyeceğini, böyle bir durumun tespiti halinde zarardan Müşteri Çalışanı’nın sorumlu olacağını, </li>

                                            <li>Platform’da  müşterinin iç ve dış paylaşıma açtığı içerikler dışındaki test, oyun ve özellikle kurumun özel ve kritik verileri işletme hedefleri vb. içeriklerin diğer Müşteri Çalışanları veya Platform dışında herhangi biriyle ekran görüntüsü veya sair şekilde paylaşılmayacağını, bu maddenin ihalinden doğacak her türlü zarardan sorumlu tutulabileceğini,Platform’u kullanacak ve/veya erişimi olan tüm kişilere Platform Kullanım Kuralları’na ilişkin bilgilendirme yapacağını, Platform kullanımının buradaki alım-satım ve sair hususlardan Ming’in sorumlu olmayacağını bilerek hareket edeceklerini, aksi halde ilgili zararlardan Müşteri Çalışanları’nın kendilerinin sorumlu olacağını,</li>

                                            <li>Platform kapsamında Ming App Kullancısının kendi kusuru veya kusuru olmaksızın uğradığı zarardan Ming’in hiçbir şekilde sorumlu olmayacağını,</li>

                                        </ol>

                                        <p>Ming App Kullanıcıları’nın hukuki veya Platform’a ilişkin talepleri olması halinde Müşteri aracılığı ile iletmeleri gerektiğini <strong>kabul, beyan ve taahhüt ederler.</strong></p>

                                        <p>Platform Kullanım Kuralları’na ilişkin taahhütlerin muhatabı işbu <strong>Platform Kullanım Kurallarını kabul, beyan ve taahhüt eden Ming App Kullanıcısı yani Müşteri Çalışanı ve esas muhatabı Ming ile Hizmet ve İş birliği Sözleşmesi’ni imzalayan Müşteri’dir.</strong> Herhangi bir ihlal halinde Ming derhal Müşteri’ye bildirimde bulunacak ve mümkünse ihlali ortadan kaldırmak için gerekli önlemleri alacaktır. Taahhüt ihlalinin Müşteri Çalışanı ve/veya personeli (Admin, proje yetkilisi vb.) kaynaklanması Müşteri’nin sorumluluğunu değiştirmeyecektir. Müşteri’nin bu sebeple uğrayacağı zararın tazmini iç ilişki olup, bu kapsamda Müşteri Çalışanı’na rücu edebilecekse de Ming herhangi bir şekilde taraf değildir.</p>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </div >

        </div >
    )
}

export default Platform