export const sehirler = [
    { label: "Adana", value: "Adana" },
    { label: "Adıyaman", value: "Adıyaman" },
    { label: "Afyonkarahisar", value: "Afyonkarahisar" },
    { label: "Ağrı", value: "Ağrı" },
    { label: "Amasya", value: "Amasya" },
    { label: "Ankara", value: "Ankara" },
    { label: "Antalya", value: "Antalya" },
    { label: "Artvin", value: "Artvin" },
    { label: "Aydın", value: "Aydın" },
    { label: "Balıkesir", value: "Balıkesir" },
    { label: "Bartın", value: "Bartın" },
    { label: "Batman", value: "Batman" },
    { label: "Bayburt", value: "Bayburt" },
    { label: "Bilecik", value: "Bilecik" },
    { label: "Bingöl", value: "Bingöl" },
    { label: "Bitlis", value: "Bitlis" },
    { label: "Bolu", value: "Bolu" },
    { label: "Burdur", value: "Burdur" },
    { label: "Bursa", value: "Bursa" },
    { label: "Çanakkale", value: "Çanakkale" },
    { label: "Çankırı", value: "Çankırı" },
    { label: "Çorum", value: "Çorum" },
    { label: "Denizli", value: "Denizli" },
    { label: "Diyarbakır", value: "Diyarbakır" },
    { label: "Düzce", value: "Düzce" },
    { label: "Edirne", value: "Edirne" },
    { label: "Elazığ", value: "Elazığ" },
    { label: "Erzincan", value: "Erzincan" },
    { label: "Erzurum", value: "Erzurum" },
    { label: "Eskişehir", value: "Eskişehir" },
    { label: "Gaziantep", value: "Gaziantep" },
    { label: "Giresun", value: "Giresun" },
    { label: "Gümüşhane", value: "Gümüşhane" },
    { label: "Hakkâri", value: "Hakkâri" },
    { label: "Hatay", value: "Hatay" },
    { label: "Iğdır", value: "Iğdır" },
    { label: "Isparta", value: "Isparta" },
    { label: "İstanbul", value: "İstanbul" },
    { label: "İzmir", value: "İzmir" },
    { label: "Kahramanmaraş", value: "Kahramanmaraş" },
    { label: "Karabük", value: "Karabük" },
    { label: "Karaman", value: "Karaman" },
    { label: "Kars", value: "Kars" },
    { label: "Kastamonu", value: "Kastamonu" },
    { label: "Kayseri", value: "Kayseri" },
    { label: "Kırıkkale", value: "Kırıkkale" },
    { label: "Kırklareli", value: "Kırklareli" },
    { label: "Kırşehir", value: "Kırşehir" },
    { label: "Kilis", value: "Kilis" },
    { label: "Kocaeli", value: "Kocaeli" },
    { label: "Konya", value: "Konya" },
    { label: "Kütahya", value: "Kütahya" },
    { label: "Malatya", value: "Malatya" },
    { label: "Manisa", value: "Manisa" },
    { label: "Mardin", value: "Mardin" },
    { label: "Mersin", value: "Mersin" },
    { label: "Muğla", value: "Muğla" },
    { label: "Muş", value: "Muş" },
    { label: "Nevşehir", value: "Nevşehir" },
    { label: "Niğde", value: "Niğde" },
    { label: "Ordu", value: "Ordu" },
    { label: "Osmaniye", value: "Osmaniye" },
    { label: "Rize", value: "Rize" },
    { label: "Sakarya", value: "Sakarya" },
    { label: "Samsun", value: "Samsun" },
    { label: "Siirt", value: "Siirt" },
    { label: "Sinop", value: "Sinop" },
    { label: "Sivas", value: "Sivas" },
    { label: "Şanlıurfa", value: "Şanlıurfa" },
    { label: "Şırnak", value: "Şırnak" },
    { label: "Tekirdağ", value: "Tekirdağ" },
    { label: "Tokat", value: "Tokat" },
    { label: "Trabzon", value: "Trabzon" },
    { label: "Tunceli", value: "Tunceli" },
    { label: "Uşak", value: "Uşak" },
    { label: "Van", value: "Van" },
    { label: "Yalova", value: "Yalova" },
    { label: "Yozgat", value: "Yozgat" },
    { label: "Zonguldak", value: "Zonguldak" }
];
