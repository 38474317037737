import React from 'react'

function Footer() {
    return (
        <footer class="footer-section ">

            <div class="footer-top pt-150 background-img-2"
                style={{ background: "url('img/footer_bg.webp')no-repeat center top / cover" }}>
                <div class="container">
                    {/* <script type="application/ld+json">
                    {
                        "@context": "https://schema.org",
                        "@type": "Organization",
                        "address": {
                            "@type": "PostalAddress",
                            "addressLocality": "İstanbul, Türkiye",
                            "streetAddress": "Harbiye Mh. Asker Ocağı CD. Süzer Plaza BL. No:6/9 Şişli"
                        },
                        "email": "info@mingapp.co",
                        "member": [
                            {
                                "@type": "Organization"
                            },
                            {
                                "@type": "Organization"
                            }
                        ],
                        "name": "Ming, Yazılım ve Danışmanlık",
                        "telephone": "+90 533 554 8612",
                        "slogan":"Oyunlaştırma ile şirket içi etkileşimi,motivasyonu, performansı ve çalışan bağlılığını artıran mobil platform."
                    }
                </script> */}
                    <div class="row justify-content-between">
                        <div class="col-md-12 col-lg-4 mb-4 mb-md-4 mb-sm-4 mb-lg-0">
                            <div class="footer-nav-wrap text-white text-center">
                                <a href="#header" class="page-scroll">
                                    <img src="/img/logo2.webp" alt="footer logo ming" height="42" width="110" class="img-fluid mb-3" />
                                </a><p itemprop="description">Oyunlaştırma ile şirket içi etkileşimi,<br />
                                    motivasyonu, performansı ve çalışan bağlılığını artıran mobil platform.</p>

                                <div class="social-list-wrap text-center">
                                    <h7>Sosya Medyada Bizi Takip Etmeyi Unutmayın ! </h7>
                                    <ul class="social-list list-inline list-unstyled">
                                        <li class="list-inline-item"><a href="https://instagram.com/mingappco" target="_blank"
                                            title="Instagram"><span class="ti-instagram"></span></a></li>
                                        <li class="list-inline-item"><a href="https://www.linkedin.com/company/mingapp/" target="_blank"
                                            title="linkedn"><span class="ti-linkedin"></span></a></li><br />
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-lg-8">
                            <div class="row">
                                <div class="col-sm-6 col-md-4 col-lg-4 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
                                    <div class="footer-nav-wrap text-white">
                                        <h5 class="mb-3 text-white">Kaynaklar</h5>
                                        <ul class="list-unstyled">
                                            <li class="mb-2"><a accesskey="y" href="#contact" id="contact3" class="page-scroll" title="help contact">Yardım</a></li>
                                            <li class="mb-2"><a accesskey="h" href="#news" class="page-scroll" title="news">Haberler</a></li>
                                            <li class="mb-2"><a accesskey="u" href="#contact" title="demo" id="demo" class="page-scroll">Ücretsiz Denemeyi Başlat</a></li>
                                            <li class="mb-2"><a accesskey="p" href="#pricing" title="pricing packet" class="page-scroll">Paketler</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-4 col-lg-4 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
                                    <div class="footer-nav-wrap text-white">
                                        <h5 class="mb-3 text-white">Şirket</h5>
                                        <ul class="list-unstyled support-list">
                                            <li class="mb-2">
                                                <a class="page-scroll" accesskey="f" href="#partner">Partner Ol</a>
                                            </li>
                                            <li class="mb-2">
                                                <a class="page-scroll" accesskey="f" href="#benefits">Faydalar</a>
                                            </li>
                                            <li class="mb-2">
                                                <a accesskey="k" href="#contact" title="carrier" id="carrier" class="page-scroll">Kariyer Başvurusu</a>
                                            </li>
                                            <li class="mb-2">
                                                <a accesskey="m" href="#trusted" class="page-scroll" title="trusted custom">Müşteriler</a>
                                            </li>
                                            {/* <!--<li class="mb-2 ">
                                                <a href="<?=($isExits==1 ? 'index.php':'')?>#team" class="page-scroll" title="team"><?php echo $lang['footer_company_team'] ?></a>
                                            </li>--> */}

                                        </ul>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-4 col-lg-4">
                                    <div class="footer-nav-wrap text-white">
                                        <h5 class="mb-3 text-white">Konum</h5>
                                        <ul class="list-unstyled support-list">
                                            <li class="mb-2 d-flex align-items-center"><span class="ti-location-pin mr-2"></span>
                                                <br />Harbiye Mh. Asker Ocağı CD. Süzer Plaza BL. No:6/9 Şişli
                                            </li>
                                            <li class="mb-2 d-flex align-items-center"><span class="ti-mobile mr-2"></span> <a
                                                href="tel:+905335548612"> +90 533 554 8612</a></li>
                                            <li class="mb-2 d-flex align-items-center"><span class="ti-email mr-2"></span><a
                                                href="mailto:info@mingapp.co"> info@mingapp.co</a></li>
                                            <li class="mb-2 d-flex align-items-center"><span class="ti-world mr-2"></span><a
                                                href="https://mingapp.co"> www.mingapp.co</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div style={{ display: "inline-block" }} class="col-md-12 col-lg-8 mb-md-4 mb-sm-4 mb-lg-0">
                            <a href="https://play.google.com/store/apps/details?id=com.ming.mingapp&gl=TR" target="_blank"
                                title="Google Play Store" ><img src="img/google_store.webp" alt="Google Play Store" style={{ height: "54px", width: "180px", paddingRight: "1px" }} /></a>
                            <a href="https://apps.apple.com/tr/app/ming/id1594153351?l=tr" target="_blank"
                                title="App Store" ><img src="img/app_store.webp" alt="App Store" style={{ height: "54px", width: "180px" }} /></a>
                            <a href="https://appgallery.huawei.com/app/C105495215" target="_blank"
                                title="Huawei App Gallery" ><img src="img/huawei.webp" alt="Huawei App Gallery" style={{ height: "54px", width: "180px" }} /></a><br />

                        </div>
                    </div>
                    <div class="footer-bottom border-gray-light mt-5 py-3">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-6 col-lg-7">
                                    <div class="copyright-wrap small-text">
                                        <p class="mb-0 text-white" itemprop="name" itemscope itemtype="https://schema.org/Organization">© 2023 Ming Yazılım ve Danışmanlık </p>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-5">
                                    <div class="terms-policy-wrap text-lg-right text-md-right text-left">
                                        <ul class="list-inline">
                                            <li class="list-inline-item"><a class="small-text" id="kvkk" href="/kvkk2" title="KVKK Genel Aydınlatma Metni">KVKK Genel Aydınlatma Metni</a></li>
                                            <li class="list-inline-item"><a class="small-text" id="platform" title="Platform Kuralları" href="/platform">Platform Kuralları</a></li>
                                            <li class="list-inline-item"><a class="small-text" id="kvkk_acik" title="KVKK Açık Rıza Onay Metni" href="/kvkk1">KVKK Açık Rıza Onay Metni</a></li>
                                            <li class="list-inline-item"><a class="small-text" id="cerez" title="Çerez Politikası" href="/cookie">Çerez Politikası</a></li>
                                            <li class="list-inline-item"><a class="small-text" id="gizlilik" title="Gizlilik Politikası" href="/privacy-policy">Gizlilik Politikası</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer