import React from 'react'
import { useNavigate } from 'react-router-dom'

function EventCard({ text, title, id, img }) {
    const navigate = useNavigate()

    return (
        <div className='event-card col-xl-3 col-sm-6 col-md-3 d-flex align-items-center justify-content-between'>
            <h4 className='py-5'>{title}</h4>
            <img src={img} style={{ width: "90%", height: "40%", objectFit: 'cover', marginBottom: 50, borderRadius: 15 }} />

            <div className='text-center pb-4'>
                <p className='text-center'>{text}</p>
                <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        navigate('/event-detail', {
                            state: {
                                id: id
                            }
                        })
                    }}
                    class="btn solid-btn2"
                >
                    Etkinlik Detayına Git
                </div>
            </div>

        </div>
    )
}

export default EventCard