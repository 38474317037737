import React, { useState } from 'react'
import Select, { components } from "react-select";
import { sehirler } from '../../constants/cities';
const InputOption = ({
    getStyles,
    Icon,
    isDisabled,
    isFocused,
    isSelected,
    children,
    innerProps,
    ...rest
}) => {
    const [isActive, setIsActive] = useState(false);
    const onMouseDown = () => setIsActive(true);
    const onMouseUp = () => setIsActive(false);
    const onMouseLeave = () => setIsActive(false);

    // styles
    let bg = "transparent";
    if (isFocused) bg = "#eee";
    if (isActive) bg = "#B2D4FF";

    const style = {
        alignItems: "center",
        backgroundColor: bg,
        color: "inherit",
        display: "flex",
        // justifyContents: 'center',
        // width: '100%'
    };

    // prop assignment
    const props = {
        ...innerProps,
        onMouseDown,
        onMouseUp,
        onMouseLeave,
        style
    };

    return (
        <components.Option
            {...rest}
            isDisabled={isDisabled}
            isFocused={isFocused}
            isSelected={isSelected}
            getStyles={getStyles}
            innerProps={props}

        >
            <input type="checkbox" style={{ display: 'flex', width: 30 }} checked={isSelected} />
            {children}
        </components.Option>
    );
};
function CheckboxDropdown({ setSelectedCities }) {

    return (
        <div className="App">
            <Select
                defaultValue={[]}
                placeholder="Faaliyet Gösterilen İller"
                isMulti
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        border: 'none',
                        borderRight: 'none',
                        minHeight: 60,
                        padding: 0,
                        fontSize: 13,
                        textAlign: 'start',
                    }),
                    placeholder: (baseStyles) => ({
                        ...baseStyles,
                        color: '#adadad',
                    })
                }}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                onChange={(options) => {
                    if (Array.isArray(options)) {
                        setSelectedCities(options.map((opt) => opt.value));
                    }
                }}
                options={sehirler}
                components={{
                    Option: InputOption
                }}
            />
            {/* <pre>{JSON.stringify({ selected: selectedOptions }, null, 2)}</pre> */}
        </div>
    );
}

export default CheckboxDropdown