import React, { useEffect, useState } from 'react'
import { sendMail } from '../../events/SendMail'

function SubscribeSection() {
    const [email, setEmail] = useState("")

    const [sendMailStatus, setSendMailStatus] = useState(0)

    useEffect(() => {
        setEmail("")
    }, [])


    const submitMail = async () => {
        if (email === "") {
            setSendMailStatus(3)
        }
        else {
            const html = `<div>
            <p>Email: ${email}</p>
        </div>`
            const res = await sendMail("Abonelik", "Abonelik", html)
            if (res.status === 200 && res !== undefined) {
                setSendMailStatus(1)
            }
            else {
                setSendMailStatus(2)
            }
        }
    }
    return (
        <div class="shape-img subscribe-wrap">
            <img src="/img/footer_top_shape.webp" width="2180" height="103" alt="Ming abone ol" class="img-fluid" />
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-6">
                        <form
                            class="subscribe-form subscribe-form-footer d-none d-md-block d-lg-block">
                            <div class="d-flex align-items-center">
                                <input type="text" class="form-control input" id="email-footer"
                                    onChange={(e) => { setEmail(e.target.value) }}
                                    name="email"
                                    placeholder="info@domain.com" />
                                <input type="button" onClick={submitMail} class="button btn solid-btn" value="Abone Ol" />
                                {
                                    sendMailStatus === 1 && (
                                        <div class="alert alert-success">
                                            E-bülten listemize kayıt oldunuz. Teşekkürler!
                                            <button type="button" class="close" onClick={() => setSendMailStatus(0)} data-dismiss="alert" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                    )
                                }

                                {
                                    sendMailStatus === 2 && (
                                        <div class="alert alert-danger" role="alert">
                                            Hata! Lütfen email bilgilerinizi kontrol edip tekrar deneyiniz.
                                            <button type="button" class="close" onClick={() => setSendMailStatus(0)} data-dismiss="alert" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                    )
                                }

                                {
                                    sendMailStatus === 3 && (
                                        <div class="alert alert-warning alert-dismissible fade show" role="alert">
                                            Dikkat! Alanlar boş bırakılamaz!
                                            <button type="button" class="close" onClick={() => setSendMailStatus(0)} data-dismiss="alert" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                    )
                                }

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SubscribeSection