import React from 'react'

function PackageSection() {
    return (
        <section class="package-section ptb-100">
            <div class="circles">
                <div class="point animated-point-1"></div>
                <div class="point animated-point-2"></div>
                <div class="point animated-point-3"></div>
                <div class="point animated-point-4"></div>
                <div class="point animated-point-5"></div>
                <div class="point animated-point-6"></div>
                <div class="point animated-point-7"></div>
                <div class="point animated-point-8"></div>
                <div class="point animated-point-9"></div>
                <div class="point animated-point-10"></div>
                <div class="point animated-point-11"></div>
                <div class="point animated-point-12"></div>
                <div class="point animated-point-13"></div>
                <div class="point animated-point-14"></div>
                <div class="point animated-point-15"></div>
                <div class="point animated-point-16"></div>
                <div class="point animated-point-17"></div>
                <div class="point animated-point-18"></div>
                <div class="point animated-point-19"></div>
                <div class="point animated-point-20"></div>
            </div>
            <div class="container" id="pricing">
                <div class="row justify-content-center">
                    <div class="col-md-8" >
                        <div class="section-heading text-center mb-5 pd-t font-card">
                            <h2>Paketlerimiz</h2>
                            <p class="lead">
                                Kurum içi iletişim deneyiminizde paketlerimizden size uygun olanı seçebilir ve kullanıma hemen başlayabilirsiniz.
                                İhtiyacınıza ve kullanımınıza göre paketinizi dilediğiniz zaman değiştirin.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-lg-4 col-md">
                        <div class="card text-center single-pricing-pack p-5 resize-card" style={{ height: '550px' }}>
                            <h4 class="mb-2">Basic</h4>
                            <div class="pricing-img mt-3 mb-4">
                                <img src="/img/basic.svg" width="65" height="65" alt="Ortak Paylaşım Modülü Anket Modülü Market" />
                            </div>

                            <div class="card-body p-0">
                                <ul class="list-unstyled text-sm pricing-feature-list">
                                    <li>Ortak Paylaşım Modülü</li>
                                    <li>Anket Modülü</li>
                                    <li>Market Modülü</li>
                                    <br /><br /><br /><br /><br />
                                </ul>
                                <div class="py-4 border-0 pricing-header">

                                </div>
                                <a href="#contact" title="ücretsiz demo" class="btn outline-btn page-scroll" id="basic" hreflang="tr">İletişime Geç</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md">
                        <div class="card text-center single-pricing-pack p-5 resize-card" style={{ height: '550px' }}>
                            <h4 class="mb-2">Business</h4>
                            <div class="pricing-img mt-3 mb-4">
                                <img src="/img/standard.svg" width="65" height="65" alt="Kullanıcı Entegrasyonu Görevler ilanlar doğum günleri" class="img-fluid" />
                            </div>

                            <div class="card-body p-0">
                                <ul class="list-unstyled text-sm pricing-feature-list">

                                    <div class="btn-group dropright">
                                        <button type="button" class="btn btn-secondary btn-ming dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Basic
                                        </button>
                                        <div class="dropdown-menu" x-placement="right-start" style={{ position: 'absolute', transform: "translate3d(111px, 0px, 0px);", top: "0px", left: "0px", willChange: "transform" }}>
                                            <a class="dropdown-item" >Ortak Paylaşım Modülü</a>
                                            <a class="dropdown-item" >Anket Modülü</a>
                                            <a class="dropdown-item" >Market Modülü</a>
                                        </div>
                                    </div>
                                    <h4>+</h4>
                                    <li>Görevler Modülü</li>
                                    <li>İlanlar Modülü</li>
                                    <li>Oyunlar Modülü</li>
                                    <li>Doğum günleri</li>
                                    <li>Kullanıcı Entegrasyonu</li>
                                </ul>
                                <div class="py-4 border-0 pricing-header">

                                </div>
                                <a href="#contact" title="ücretsiz demo kullanıcı entegrasyonu" class="btn outline-btn page-scroll business" id="business" hreflang="tr">İletişime Geç</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md resize-card3">
                        <div class="card text-center single-pricing-pack p-5 resize-card" style={{ height: '550px' }}>
                            <h4 class="mb-2">Enterprise</h4>
                            <div class="pricing-img mt-3 mb-4">
                                <img src="/img/unlimited.svg" width="65" height="65" alt="Business okr işletme hedefleri iş ilanları tam entegrasyon" class="img-fluid" />
                            </div>

                            <div class="card-body p-0">
                                <ul class="list-unstyled text-sm pricing-feature-list">
                                    <div class="btn-group dropright">
                                        <button type="button" class="btn btn-secondary btn-ming dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Business
                                        </button>
                                        <div class="dropdown-menu" x-placement="right-start" style={{ position: 'absolute', transform: "translate3d(111px, 0px, 0px);", top: "0px", left: "0px", willChange: "transform" }}>
                                            <a class="dropdown-item" >Ortak Paylaşım Modülü</a>
                                            <a class="dropdown-item" >Anket Modülü</a>
                                            <a class="dropdown-item" >Market Modülü</a>
                                            <a class="dropdown-item" >Görevler Modülü</a>
                                            <a class="dropdown-item" >İlanlar Modülü</a>
                                            <a class="dropdown-item" >Oyunlar Modülü</a>
                                            <a class="dropdown-item" >Doğum günleri</a>
                                            <a class="dropdown-item" >Kullanıcı Entegrasyonu</a>
                                        </div>
                                    </div>
                                    <h4>+</h4>
                                    <li>İşletme Hedefleri</li>
                                    <li>OKR</li>
                                    <li>İş İlanları</li>
                                    <li>Tam Entegrasyon</li> <br />
                                </ul>
                                <div class="py-4 border-0 pricing-header">
                                </div>
                                <a href="#contact" title="ücretsiz demo Business okr" class="btn outline-btn page-scroll" id="enterprise" hreflang="tr">İletişime Geç</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PackageSection