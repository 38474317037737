import React from 'react'

function Kvkk1() {
    return (
        <div class="main">

            <section class="hero-section ptb-100 background-img"
                style={{ background: "url('img/hero_bg_2.webp')no-repeat center center / cover" }}>
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-md-8 col-lg-7">
                            <div class="page-header-content text-white text-center pt-sm-5 pt-md-5 pt-lg-0">
                                <h1 id="kvkk-1" style={{ color: "white" }} class="post-title"> Kişisel Verilerin Korunması Mevzuatı Uyarınca Açık Rıza Onay Metni
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div class="module ptb-100">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <article class="post">
                                <div class="post-wrapper">
                                    <div class="post-content">

                                        <p>
                                            6698 sayılı Kişisel Verilerin Korunması Kanunu'na (“Kanun”)
                                            göre Ming Danışmanlık Yazılım ve Pazarlama Limited Şirketi
                                            (Bundan sonra “Ming” olarak anılacaktır) sizinle ilgili
                                            kişisel verileri işlemesi sebebiyle veri sorumlusu ve/veya veri işleyen
                                            olarak tanımlanmaktadır. Ming, Kişisel Verilerin Korunması Mevzuatı Uyarınca
                                            Genel Aydınlatma Metni (“Aydınlatma Metni”) KVKK’nın 10. maddesinde yer alan
                                            “Veri Sorumlusu’nun Aydınlatma Yükümlülüğü”  başlıklı maddesi uyarınca; veri sorumlusunun kimliği, kişisel verilerinizin
                                            toplanma yöntemi ve hukuki sebebi, bu verilerin hangi amaçla işleneceği, kimlere ve hangi
                                            amaçla aktarılabileceği, veri işleme süresi ve KVKK’nın 11. maddesinde sayılan haklarınızın
                                            neler olduğu ile ilgili bilgilendirme amacıyla hazırlanmıştır. İlgili metne <a href="/kvkk2" title="kvkk">BURADAN</a> ulaşabilirsiniz.</p>


                                        <p>
                                            Kişisel verilerinizi, Kanun ve ilgili yasal mevzuat kapsamında
                                            ve “veri sorumlusu ve/ veya veri işleyen” sıfatımızla <a href="/kvkk2" title="kvkk">Kişisel Verilerin
                                                Korunması Mevzuatı Uyarınca Aydınlatma Metni ve Ekleri’nde</a> belirtmiş olduğumuz kapsamda işlemekteyiz.

                                        </p>

                                        <p>
                                            Ming olarak, kişisel verilerinizin güvenliğine ve gizliliğine
                                            önem veriyor ve bu kapsamda kişisel verilerinizin işlenmesi
                                            ve üçüncü kişilere aktarılması süreçlerinde, kişisel verilerinizin
                                            hukuka aykırı olarak işlenmesini ve kişisel verilerinize hukuka aykırı
                                            olarak erişilmesini önlemek ve kişisel verilerinizin muhafazasını
                                            sağlamak için en üst seviyede güvenlik tedbirlerini almaya çalışıyoruz.
                                        </p>

                                        <p>
                                            Ming tarafından kişisel verilerimin işlenmesine ilişkin bilgilendirmeleri
                                            içeren “ <strong>Kişisel Verilerin Korunması Mevzuatı Uyarınca Aydınlatma Metni ” </strong>
                                            ve Ekleri ve <strong> “Kişisel Verilerin Korunması Mevzuatı Uyarınca Açık Rıza Onay
                                                Metni” incelenmek üzere tarafımla paylaşılmış ve tarafımca incelenmiş olduğunu;
                                                bu metni imzalayarak “Kişisel Verilerin Korunması Mevzuatı Uyarınca Aydınlatma Metni
                                                ” ve Ekleri” ve “Kişisel Verilerin Korunması Mevzuatı Uyarınca Açık Rıza Onay Metni”</strong>
                                            kapsamında yer alan bilgilere ilişkin olarak tarafıma gerekli aydınlatmanın yapıldığını,
                                            metinleri okuduğumu ve anladığımı, herhangi bir etki altında kalmaksızın kişisel verilerimin
                                            işlenmesine, yurtiçi ve yurtdışı kişi ve kurumlar ile paylaşılmasına açık bir şekilde rızamı
                                            verdiğimi kabul, beyan ve taahhüt ederim.

                                        </p>

                                    </div>



                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Kvkk1