import React from 'react'
import EventCard from '../components/Events/EventCard'

const EVENTS = [
    {
        id: 0,
        title: 'Waffle Etkinliği',
        img: 'https://image.hurimg.com/i/hurriyet/75/750x422/5f5255dbd3806c209020ff2f.jpg',
        text: "Waffle Etkinliği,ekip üyelerinin bir araya gelip hem işlerini halletmelerini hem de lezzetli waffle'ların tadını çıkarmalarını sağlar."
    },
    {
        id: 1,
        title: 'Kahve Etkinliği',
        img: 'https://www.makbul.com/Content/global/images/products/2/228/ORG-turk-kahvesi-kg446.jpg',
        text: "İş yerinde kahve molası zamanı! Kahve tutkunları için özel olarak düzenlenen bu etkinlik, herkesin favori kahve çeşitlerini paylaşabileceği ve yeni lezzetler keşfedebileceği bir ortam sunuyor."
    },
    {
        id: 2,
        title: 'Dondurma Etkinliği',
        img: 'https://www.buseterim.com.tr/upload/default/2022/8/6/6802.jpg',
        text: "İş yerinde yazın tadını çıkarmak için dondurma günü düzenlemeye hazır mısınız?"
    },
    {
        id: 3,
        title: 'Milkshake Etkinliği',
        img: 'https://cdn.yemek.com/mnresize/1250/833/uploads/2022/08/cikolatali-milkshake-yemekcom.jpg',
        text: "Sıcak yaz günlerinde serinlemek için iş yerinde bir milkshake partisi düzenlemeye ne dersiniz?"
    },
    {
        id: 4,
        title: 'Vitamin Bar Etkinliği',
        img: 'https://www.eglenceorganizasyonu.com.tr/editor/images/vitamin-bar-organizasyonu.jpg',
        text: "Sağlıklı yaşam ve enerjik bir iş ortamı için bir araya geliyoruz! Bu etkinlikte, vitamin barımızda çeşitli meyve suyu ve smoothie seçenekleriyle dolu bir şölen sunuyoruz."
    },
    {
        id: 5,
        title: 'Şirketinize Özel Kutlamalar',
        img: '/img/event-detail/ozelgun2.png',
        text: "Şirket olarak özel günleri kutlamak ve birlikte keyifli zamanlar geçirmek için buradayız."
    },
    {
        id: 6,
        title: 'Kurumsal Piknik Organizasyonları',
        img: 'https://harbiyiyorum.com/wp-content/uploads/istanbul-en-iyi-7-piknik-alani.jpg',
        text: "Şirketimiz olarak doğayla iç içe, eğlenceli bir gün geçirmek için bir araya gelmeye ne dersiniz?"
    },
    {
        id: 7,
        title: 'Kurumsal Kahkaha Yogası',
        img: 'https://www.aklotus.net/upload/kahkahayogas%C4%B1.jpg',
        text: "İş stresini azaltmak ve ekip ruhunu güçlendirmek için kurumsal kahkaha yogası etkinliklerimizle çalışanlarınızı eğlendirebiliriz."
    },
    {
        id: 8,
        title: 'Drink and Draw Etkinliği',
        img: 'https://static.wixstatic.com/media/e277f2_b45b7ddda5f24b52ba8d2b8456df1507~mv2.jpg/v1/fill/w_640,h_478,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/e277f2_b45b7ddda5f24b52ba8d2b8456df1507~mv2.jpg',
        text: "Yaratıcı yanlarını keşfetmek isteyen çalışanlarınız için bir 'Drink and Draw' etkinliği düzenleyin."
    },
    {
        id: 9,
        title: 'Happy Hour Etkinliği',
        img: '/img/event-detail/happy.png',
        text: "Haftanın yorgunluğunu atmak ve birlikte keyifli vakit geçirmek için tüm çalışanlarımızı mutluluk saatinde bir araya bekliyoruz!"
    },


]

function Events() {

    return (
        <div class="main">

            <section class="hero-section ptb-100 background-img"
                style={{ background: "url('/img/hero_bg_2.webp')no-repeat center center / cover" }}>
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-md-8 col-lg-12">
                            <div class="page-header-content text-white text-center pt-sm-5 pt-md-5 pt-lg-0">
                                <h3 class="text-white mb-0" id="news-1">
                                    Ming olarak, iş yerinde çalışanlarınızın motivasyonunu artırmak ve şirket kültürünü güçlendirmek için çeşitli etkinlikler sunuyoruz. İşte sizin için özel olarak düzenleyebileceğimiz bazı ofis etkinlikleri
                                </h3>
                                <div class="custom-breadcrumb">
                                    {/* <ol class="breadcrumb d-inline-block bg-transparent list-inline py-0">
                                    <li class="list-inline-item breadcrumb-item"><a href="index.php" title="anasayfa">Anasayfa</a></li>
                                    <li class="list-inline-item breadcrumb-item"><a href="index.php#news" title="haberler">Haberler</a></li>
                                    <li class="list-inline-item breadcrumb-item active" title="ming">Ming</li>
                                </ol> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <div className='row col-lg-12 p-5 d-flex' style={{ justifyContent: 'space-evenly' }}>
                {
                    EVENTS.map((event) => [
                        <EventCard id={event.id} title={event.title} img={event.img} text={event.text} />
                    ])
                }

            </div>


            <div class="module ptb-100">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <article class="post">
                                <div class="post-preview">
                                    {/* <!--<img src=""  alt="article" class="img-fluid"/>--> */}
                                </div>
                                <div class="post-wrapper">

                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Events