import React from 'react'

function News2() {
    return (
        <div class="main">
            <section class="hero-section ptb-100 background-img"
                style={{ background: "url('/img/hero_bg_2.webp')no-repeat center center / cover" }}>
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-md-8 col-lg-7">
                            <div class="page-header-content text-white text-center pt-sm-5 pt-md-5 pt-lg-0">
                                <h3 class="text-white mb-0" id="news-2">Yorglass’tan çalışanlar arasında uçtan uca iletişimi artıran yenilikçi uygulamalar</h3>
                                <div class="custom-breadcrumb">
                                    <ol class="breadcrumb d-inline-block bg-transparent list-inline py-0">
                                        <li class="list-inline-item breadcrumb-item"><a href="index.php" title="anasayfa">Anasayfa</a></li>
                                        <li class="list-inline-item breadcrumb-item"><a href="index.php#news" title="haberler">Haberler</a></li>
                                        <li class="list-inline-item breadcrumb-item active" title="Ming">Ming</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div class="module" style={{ paddingTop: "20px" }}>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <article class="post">
                                <div class="post-preview"><img src="/img/blog/yorglass.webp" width="800" height="362" alt="yorglass merve yorgancılar" class="img-fluid" /></div>
                                <div class="post-wrapper">
                                    <div class="post-header">
                                        <h1 class="post-title">
                                            Cama hayat veren Yorglass, çalışan motivasyonu ve bağlılığını artırmak için attığı adımlara sosyal platform Ming ile bir yenisini daha ekledi</h1><br />
                                        <ul class="post-meta">
                                            <li>Eylül 12, 2022</li>
                                        </ul>
                                    </div>
                                    <div class="post-content">
                                        <p style={{ fontWeight: "bold" }}>Yarım asırdır iş birliği ve dayanışma bilinciyle cama hayat veren güvenilir ve global tedarikçi Yorglass; iş stratejilerini, operasyonlarını ve kültürünü de bu ilkeler ışığında sürdürüyor. Ortak kalkınma kültürünün gücüne duyduğu inanç ve sürdürülebilirlik bakış açısı çerçevesinde açık iletişim kanallarını önemseyen Yorglass,
                                            yenilikçi İK uygulamalarını şirketin her katmanına entegre ederken farklı platformlardan da yararlanıyor. Bu kapsamda çalışanların birbirleriyle ve kurumla olan etkileşimini, motivasyonunu ve bağlılığı artırmayı amaçlayan ilk kurum içi start-up programının ürünü olan Ming’i kullanan Yorglass, oyunlaştırmanın dinamiklerinden faydalanarak şirket içi sosyal ortam oluşturuyor.</p>
                                        <br />
                                        <p>İşe ve emeğe değer veren, tüm fikirlere açık, evrensel kaliteyi temel alan, tüm paydaşların etkileşimde olduğu bir çalışma kültürü benimseyen Yorglass, herkes için gelişimin esas olduğu İK yaklaşımıyla dikkat çekiyor. Alanında uzmanlaşmış, yeniliğe ve gelişime açık insan değerinin yetiştirilmesini destekleyen Yorglass, çalışanlarının kariyerlerine ve kişisel gelişimlerine de katkı sağlıyor. Çalışanların birbirleriyle ve kurumla olan etkileşimini artırmayı hedefleyen bir sosyal platform olan
                                            Ming uygulamasının hayata geçirilmesi ile Yorglass; çalışanlarının hedeflerini takip edebilme, kaynak tasarrufu sağlama, kurumun dijitalleşmesine katkı sağlama ve farklı şehirlerdeki işletmelerde görev alan çalışanlarının birbirine bağlanması gibi birçok avantaj sağlamış oluyor. Ming Kurucusu ve Yorglass Yönetim Kurulu Üyesi Merve Yorgancılar Işıtmak, birbirinden farklı ama bir kurumun ihtiyaç duyabileceği birçok özelliği bünyesinde barındıran uygulamayı ihtiyaçtan doğan bir kurum içi girişimcilik örneği olarak tanımlıyor.</p>
                                        <blockquote class="blockquote">
                                            <p>Ming, çalışanlar arasında uçtan uca etkileşim sağlıyor.</p>
                                        </blockquote>
                                        <p>Yorglass olarak değişen dünyanın dinamiklerine adapte olmak için sadece operasyonel anlamda değil insan kaynakları gibi şirketin temelini oluşturan farklı departmanlarda da yenilikçi perspektifler benimsediklerini söyleyen <b>Merve Yorgancılar Işıtmak</b>;
                                            “Sunduğumuz çözümleri müşterilerimize değer katacak şekilde yeniden yapılandırma amacıyla dijitalleşme yolculuğumuza önce insan kaynakları süreçlerinden başladık ve işe alımdan itibaren bütün süreçlerimizi dijitale taşıdık. İK operasyonlarımızı bulut temelli bir insan kaynakları uygulaması olan Success Factor üzerinden yönetmeye başladık.
                                            Farklı lokasyonlardaki arkadaşlarımızla ortak hedef kültürünü oluşturabilmek adına dijitalleşme sürecimize üretimde çalışan personelimizi de dahil ettik. Açıkçası kurum içi iletişimin güçlü olduğu şirketlerde ortak hedef mantığının ve kültürünün daha hızlı ve sağlam oluştuğunuzu gördük. Bunun içinse <b> Ming</b> projesini hayata geçirdik.
                                            Ortak hedef mantığını esas alan oyunlaştırma tabanlı bu uygulama, her şirkete kendi bütçesine göre market yönetimi imkânı sunuyor ve
                                            böylece motivasyon bütçelerinin daha iyi yönetilmesini sağlıyor. Aynı zamanda yeni bordro modülü sayesinde bütün çalışanlar, bordrolarını Ming üzerinden alabildiği için, insan kaynakları operasyonları da kolaylaşıyor.
                                            Şöyle ki, uygulama içi aksiyonlardan puan kazandırıyor, kazanılan puanları markette ödüle dönüştürüyor. Kurum içi sosyal bir platform olan Ming, kurum içi iletişimi açık hale getirerek tüm beyaz ve mavi yakalı çalışanlarımızla uçtan uca etkileşim halinde kalmamızı sağlıyor” dedi.</p>

                                        <blockquote class="blockquote">
                                            <p>Çift taraflı iletişim ve ödül sistemiyle aidiyet duygusunu pekiştiriyor.</p>
                                        </blockquote>

                                        <p>Ming’in şirketin her katmanında karşılık bulabildiğine dikkat çeken Yorgancılar; “Uygulama, e- posta adresi gerektirmiyor ve çalışanlar mail adresleri olmadan sadece telefon numarası ile giriş yapabiliyor. Bu noktada SMS doğrulama sistemi ile güvenliği üst seviyeye taşıdık,
                                            sadece kurum içinde aktif çalışanların sisteme erişebilmesini ve bu erişimi de yaptığımız entegrasyonlarla sağladık. Böylelikle hem beyaz hem de mavi yaka çalışanlarla anında iletişim kurabiliyoruz. Çalışanların paylaşım ya da yorum yapma imkânı olduğu için kurum içi iletişim çift yönlü hale geliyor; bu da kurumun çalışanını daha iyi tanımasını ve analiz etmesini sağlıyor.
                                            orglass’ın ilk kurum içi start-up programının ürünü olan Ming aynı zamanda kurumların sosyal medya hesaplarında daha şeffaf iletişim süreci yürütmelerini de sağlıyor.
                                            Çünkü çalışanların uygulama içinde yaptığı yorumlar doğrudan farklı platformlar üzerinden rahatlıkla yayınlanabiliyor.
                                            Platform; anketler, duyurular, iş ilanları, doğum günleri ve öneri gibi tüm çalışanları ilgilendiren konuları tek uygulama üzerinden yönetme imkânı sunuyor. Özelleştirilebilen puan ekonomisi sayesinde de çalışan sayısı ve bütçe girilerek kuruma özel sadakat sistemi oluşturuyor,
                                            market içeriklerini belirleyerek çalışanlarımızı motive edecek ödülleri eklememizi sağlıyor” diye belirtti.</p>
                                    </div>
                                    {/* <div class="post-footer">
                                        <div class="post-tags"><a href="https://oyunanlatimi.com/yorglasstan-calisanlar-arasinda-uctan-uca-iletisimi-artiran-yenilikci-uygulamalar/" title="yorglass ming" target="_blank">Devamı için Kaynağa Git </a></div>
                                    </div> */}
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default News2