import React from 'react'

function FeatureSection() {
    return (
        <section class="feature-section ptb-100" >
            <div class="circles">
                <div class="point animated-point-1"></div>
                <div class="point animated-point-2"></div>
                <div class="point animated-point-3"></div>
                <div class="point animated-point-4"></div>
                <div class="point animated-point-5"></div>
                <div class="point animated-point-6"></div>
                <div class="point animated-point-7"></div>
                <div class="point animated-point-8"></div>
                <div class="point animated-point-9"></div>
                <div class="point animated-point-10"></div>
                <div class="point animated-point-11"></div>
                <div class="point animated-point-12"></div>
                <div class="point animated-point-13"></div>
                <div class="point animated-point-14"></div>
                <div class="point animated-point-15"></div>
                <div class="point animated-point-16"></div>
                <div class="point animated-point-17"></div>
                <div class="point animated-point-18"></div>
                <div class="point animated-point-19"></div>
                <div class="point animated-point-20"></div>
            </div>
            <div class="container" id="modules">
                <div class="row align-items-center justify-content-between pd-t" >
                    <div class="col-md-6 modules">
                        <div class="float-left">

                            <img src="/img/circle.webp" alt="modul" class="rotate position-absolute" />
                            <img src="/img/circle.webp" alt="modul" class="rotate2 position-absolute" />
                            <div class="download-img resize">
                                <img src="/img/appw2.webp" width="871" height="900" alt="çalışanların birbiriyle ve kurumla arasında etkileşimi artırmak , oyunlaştırma dinamikleri " class="img-fluid" />
                            </div>
                        </div>

                    </div>
                    <div class="col-md-6" style={{ marginLeft: "50px !important" }}>
                        <div class="feature-contents section-heading font-card">
                            <h2>Ming ile şirketinize neler sağlıyoruz?</h2>
                            <p>Ming çalışanların birbiriyle ve kurumla arasında etkileşimi artırmayı, oyunlaştırma dinamiklerini kullanarak, uygulama içi aksiyonlardan puan kazandıran,
                                kazanılan puanları market modülü üzerinden ödüle dönüştüren kurum içi platformdur.</p>

                            <div class="feature-content-wrap">
                                <ul class="nav nav-tabs feature-tab" data-tabs="tabs">
                                    <li class="nav-item" >
                                        <a class="nav-link active h6" href="#tab6-1" data-toggle="tab">
                                            <span class="ti-palette"></span>
                                            Paylaşım Duvarı
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link h6" href="#tab6-2" data-toggle="tab">
                                            <span class="ti-agenda"></span>
                                            Görevlerim
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link h6" href="#tab6-3" data-toggle="tab">
                                            <span class="ti-bar-chart"></span>
                                            Hedefler
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link h6" href="#tab6-4" data-toggle="tab">
                                            <span class="ti-clipboard"></span>
                                            Anket
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link h6" href="#tab6-5" data-toggle="tab">
                                            <span class="ti-announcement"></span>
                                            İlanlar
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link h6" href="#tab6-6" data-toggle="tab">
                                            <span class="ti-world"></span>
                                            İş İlanları
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link h6" href="#tab6-7" data-toggle="tab">
                                            <span class="ti-control-play"></span>
                                            Oyunlar
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link h6" href="#tab6-8" data-toggle="tab">
                                            <span class="ti-face-smile"></span>
                                            Doğum günleri
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link h6" href="#tab6-10" data-toggle="tab">
                                            <span class="ti-dashboard"></span>
                                            Liderlik Tablosu
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link h6" href="#tab6-11" data-toggle="tab">
                                            <span class="ti-cup"></span>
                                            Ödül Marketi
                                        </a>
                                    </li>
                                </ul>
                                <div class="tab-content feature-tab-content">
                                    <div class="tab-pane active" id="tab6-1">
                                        <div class="single-feature">
                                            <div class="d-flex align-items-center mb-2">
                                                <h5 class="mb-0">Kurum içi iletişim engel tanımasın </h5>
                                            </div>
                                            <span class="ti-location-arrow rounded mr-3 icon icon-color-1 rounded-modules"></span>
                                            E-posta gerektirmeden ister masa başı ister saha çalışanı olsun tüm çalışanlarınızı platforma dahil edin.<br />
                                            <span class="ti-location-arrow rounded mr-3 icon icon-color-1 rounded-modules"></span>
                                            İster evden ister ofisten çalışanlarınızla sürekli iletişimde kalın, etkinlik oluşturun, duyuru paylaşın ve çalışanlarınızın sosyal iletişim ve paylaşım kurmalarına olanak sağlayın.
                                        </div>
                                    </div>
                                    <div class="tab-pane" id="tab6-2">
                                        <div class="single-feature">
                                            <div class="d-flex align-items-center mb-2">
                                                <h5 class="mb-0">İş süreçlerinin puan kazandıran hali </h5>
                                            </div>
                                            <span class="ti-check-box rounded mr-3 icon icon-color-2 rounded-modules"></span>
                                            E-postalarda kaybolan, uzaktan çalışma sürecinde aksayan işlerinizi görev yönetimi modülü ile mobile taşıyın; görev gönderirken de yaparken de puan kazanın. <br />
                                            <span class="ti-check-box rounded mr-3 icon icon-color-2 rounded-modules"></span>
                                            Görev sonrası değerlendirme sistemi ile hem görevi gönderen hem de yapan hakkında performans verilerine erişim sağlayın.<br />
                                        </div>
                                    </div>
                                    <div class="tab-pane" id="tab6-3">
                                        <div class="single-feature">
                                            <div class="d-flex align-items-center mb-2">
                                                <h5 class="mb-0">Hedeflere ekip ruhu ile ulaşın</h5>
                                            </div>
                                            <span class="ti-direction rounded mr-3 icon icon-color-3 rounded-modules"></span>
                                            Kurumsal hedeflerinizi, amaçlarınızı ve süreçlerinize bağlı başarı kriterlerinizi tüm çalışanlarınızla paylaşmak için OKR yaklaşımına göre tasarlanmış “İşletme Hedefleri” modülüne ekleyebilirsiniz.<br />
                                            <span class="ti-direction rounded mr-3 icon icon-color-3 rounded-modules"></span>
                                            Sistemlerinizi platformunuza entegre ederek kurum hedeflerinizi anlık olarak takip edin, hızlı aksiyon alın ve sürdürülebilir kaynak yönetimi konusunda farkındalık yaratın.<br />
                                        </div>
                                    </div>
                                    <div class="tab-pane" id="tab6-4">
                                        <div class="single-feature">
                                            <div class="d-flex align-items-center mb-2">
                                                <h5 class="mb-0">Çalışanlarınızın düşüncelerini anında öğrenin</h5>
                                            </div>
                                            <span class="ti-clipboard rounded mr-3 icon icon-color-4 rounded-modules"></span>
                                            Çalışanlarınızın düşüncelerini ve önerilerini ister periyodik anketlerle isterseniz anlık olarak öğrenebilir ve kayıt altına alabilirsiniz. <br />
                                            <span class="ti-clipboard rounded mr-3 icon icon-color-4 rounded-modules"></span>
                                            Çalışanlarınızı kişisel hesaplarından verdikleri cevapları toplayarak sonuçlara ulaşmanızı ve analizlerinizi kolaylaştırıyoruz.<br />
                                        </div>
                                    </div>
                                    <div class="tab-pane " id="tab6-5">
                                        <div class="single-feature">
                                            <div class="d-flex align-items-center mb-2">
                                                <h5 class="mb-0">Dayanışma kültürünü iş dışına da taşıyın</h5>
                                            </div>
                                            <span class="ti-announcement rounded mr-3 icon icon-color-1 rounded-modules"></span>
                                            Çalışanlarınızın kullanmadığı ve elinden çıkarmak istedikleri eşyalarını ilanlar modülü ile uygulamaya yükleyerek değerlendirmelerine, ihtiyacı olan diğer çalışanlarla buluşturmasına olanak sağlayabilirsiniz. <br />
                                            <span class="ti-announcement rounded mr-3 icon icon-color-1 rounded-modules"></span>
                                            Böylece hem eşyaları dönüşüme kazandırmaya hem de çalışanlarınızın ekonomilerine katkı sağlayabilirsiniz.<br />
                                        </div>
                                    </div >
                                    <div class="tab-pane" id="tab6-6">
                                        <div class="single-feature">
                                            <div class="d-flex align-items-center mb-2">
                                                <h5 class="mb-0">Çalışanlarınızın network’ünü şirkete kazandırın</h5>
                                            </div>
                                            <span class="ti-link rounded mr-3 icon icon-color-6 rounded-modules"></span>
                                            Açık pozisyonlarınızı ve kurum içi kariyer fırsatlarını ilk önce çalışanlarınıza duyurun, açık pozisyonlarda kurum içi rotasyona olanak tanıyabilirsiniz. <br />
                                            <span class="ti-link rounded mr-3 icon icon-color-6 rounded-modules"></span>
                                            “Arkadaşını Getir” özelliği ile çalışanların çevrelerine iş ilanlarını paylaşmasına ve işe başlayan referanslı çalışanlar ile puan kazandırın.<br />
                                        </div >
                                    </div >
                                    <div class="tab-pane " id="tab6-7">
                                        <div class="single-feature">
                                            <div class="d-flex align-items-center mb-2">
                                                <h5 class="mb-0">Oyunun birleştirici ve eğlendirici gücünü kullanın. </h5>
                                            </div>
                                            <span class="ti-game rounded mr-3 icon icon-color-1 rounded-modules"></span>
                                            Özelleştirilebilir soru setleri ve bilgi yarışması havuzu ile çalışanlarınıza bilgiye dayalı eğlenceli bir öğrenme ortamı yaratın.<br />
                                            <span class="ti-game rounded mr-3 icon icon-color-1 rounded-modules"></span>
                                            Sektörel bilgileri ve kurum içi eğitimleri, bilgi yarışmalarıyla kalıcı hale gelmesini sağlayın.<br />
                                        </div >
                                    </div >
                                    <div class="tab-pane " id="tab6-8">
                                        <div class="single-feature">
                                            <div class="d-flex align-items-center mb-2">
                                                <h5 class="mb-0">Çalışanlarınızın özel günlerinde onların yanında olun</h5>
                                            </div>
                                            <span class="ti-gift rounded mr-3 icon icon-color-2 rounded-modules"></span>
                                            Doğum günleri olan çalışanlarınızın bildirimi her gün iş arkadaşlarına bildirilir, dileyenler kişisel doğum günü dileklerini iletebilirler. <br />
                                            <span class="ti-gift rounded mr-3 icon icon-color-2 rounded-modules"></span>
                                            Ayrıca doğum günü puanı ile çalışanlarınızı mutlu edebilirsiniz.<br />
                                        </div >
                                    </div >
                                    <div class="tab-pane" id="tab6-10">
                                        <div class="single-feature">
                                            <div class="d-flex align-items-center mb-2">
                                                <h5 class="mb-0">Dengeli bir rekabet ile motivasyonu yüksek tutun</h5>
                                            </div>
                                            <span class="ti-info rounded mr-3 icon icon-color-3 rounded-modules"></span>
                                            Aylık olarak yenilenen bireysel liderlik tablosu ile çalışanlar arasında geliştirici bir rekabet ortamı yaratın.<br />
                                            <span class="ti-info rounded mr-3 icon icon-color-3 rounded-modules"></span>
                                            Kurumsal liderlik tablosu ile hedefler doğrultusunda işletme, şube veya birimlerinizi yarıştırabilirsiniz ve nihayetinde ödüllendirebilirsiniz.<br />
                                        </div >
                                    </div >
                                    <div class="tab-pane" id="tab6-11">
                                        <div class="single-feature">
                                            <div class="d-flex align-items-center mb-2">
                                                <h5 class="mb-0">Kişiye özel motivasyon marketi</h5>
                                            </div>
                                            <span class="ti-crown rounded mr-3 icon icon-color-4 rounded-modules"></span>
                                            Kendi puan ekonomisi olan Ming market ile çalışanlarınıza tüm aksiyonlarından puan kazandırın.Marketin içeriğini ve yer alacak ürünleri sizin talepleriniz doğrultusunda belirliyoruz. <br />
                                            <span class="ti-crown rounded mr-3 icon icon-color-4 rounded-modules"></span>
                                            Bütçenize özel oluşturulan market üzerinden çalışanlarınız puanları ödüle dönüştürebilirler.<br />
                                        </div >
                                    </div >
                                </div >
                            </div >
                        </div >
                    </div >
                </div >
            </div >
        </section >
    )
}

export default FeatureSection