import React from 'react'

function News3() {
    return (
        <div class="main">
            <section class="hero-section ptb-100 background-img"
                style={{ background: "url('/img/hero_bg_2.webp')no-repeat center center / cover" }}>
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-md-8 col-lg-7">
                            <div class="page-header-content text-white text-center pt-sm-5 pt-md-5 pt-lg-0">
                                <h3 class="text-white mb-0" id="news-3">İK stratejilerine ilham veren Ming uygulaması fuara çıkarma yapacak!</h3>
                                <div class="custom-breadcrumb">
                                    <ol class="breadcrumb d-inline-block bg-transparent list-inline py-0">
                                        <li class="list-inline-item breadcrumb-item"><a href="index.php" title="anasayfa">Anasayfa</a></li>
                                        <li class="list-inline-item breadcrumb-item"><a href="index.php#news" title="haberler">Haberler</a></li>
                                        <li class="list-inline-item breadcrumb-item active" title="Ming">Ming</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div class="module" style={{ paddingTop: "20px" }}>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <article class="post">
                                <div class="post-preview"><img src="/img/blog/mobilefest.webp" width="800" height="362" alt="yorglass merve yorgancılar" class="img-fluid" /></div>
                                <div class="post-wrapper">
                                    <div class="post-header">
                                        <h1 class="post-title">
                                            Ming, Türkiye ve dünyadan çok sayıda bilişim ve start-up yatırımcısına ev sahipliği yapan Mobilefest İletişim Teknolojileri Fuarı ve Konferansı’nda olacak</h1><br />
                                        <ul class="post-meta">
                                            <li>Ocak 19, 2023</li>
                                        </ul>
                                    </div>
                                    <div class="post-content">
                                        <p style={{ fontWeight: "bold" }}>Çalışanların birbirleriyle ve kurumla olan etkileşimini, motivasyonunu ve bağlılığını artırmayı amaçlayan ilk kurum içi start-up programının ürünü olan Ming; 26-28 Ocak tarihleri arasında İstanbul Kongre Merkezi’nde düzenlenecek, yerli bilişim firmalarının yeni
                                            teknolojilerini tanıtıp uluslararası iş birlikleri sağlayacağı Mobilefest İletişim Teknolojileri Fuarı ve Konferansı’nda tanıtılacak.</p>
                                        <br />
                                        <p>Oyunlaştırma pratikleri ile şirket içi etkileşimi, motivasyonu, performansı ve çalışan bağlılığını artıran mobil platform Ming uygulaması, 26-28 Ocak tarihleri arasında İstanbul Kongre Merkezi’nde yapılacak Mobilefest İletişim Teknolojileri Fuarı ve Konferansı’nda ziyaretçilerle buluşacak. Ming’in sunduğu avantajların ve bu platform ile yapılacak yenilikçi insan kaynakları uygulamalarının tanıtılacağı fuarda aynı zamanda ziyaretçiler,
                                            start-up alanında yer alan 2D04 numaralı stantta Ming uygulamasının demosunu deneyimleme fırsatı bulacak.</p>
                                        <blockquote class="blockquote">
                                            <p>Çalışanların birbirleriyle ve kurumla olan etkileşimini artırıyor</p>
                                        </blockquote>
                                        <p>Ming gibi yenilikçi ve uçtan uca iletişime olanak tanıyan bir uygulama ile Mobilefest İletişim Teknolojileri Fuarı ve Konferansı’nda yer alacak olmanın heyecanı içinde olduklarını dile getiren&nbsp;<strong>Ming Kurucusu Merve Yorgancılar Işıtmak</strong>; “Bilgi iletişim teknolojilerinin ulaştığı baş döndürücü hız, hayatın her alanında karşılık buluyor. Bu bakış açısıyla hayata geçen Ming; çalışma hayatındaki iletişim kopukluklarını ve evden çalışma sürecindeki motivasyon eksikliklerini gidermeyi, şirketin hedeflerini görünür kılarak her bir çalışanı aynı hedefe koşturmayı, aynı zamanda oyunlaştırmanın birleştirici gücünü kullanarak şirket içi sosyal ortam oluşturmayı hedefliyor. Uygulama; çalışanların hedeflerini takip edebilme, kaynak tasarrufu sağlama, kurumun dijitalleşmesine katkıda bulunma ve farklı şehirlerdeki işletmelerde görev alan çalışanların birbirine bağlanması gibi birçok avantajı da beraberinde getiriyor. Dijitalleşmenin sağladığı hız, esneklik, uzaktan erişim gibi olanakları başarıyla kullanan bu uygulama, ortak hedef mantığını esas alan oyunlaştırma tabanlı bir platform olarak motivasyonu en üst seviyeye çıkarıyor. Aynı zamanda yeni bordro modülü sayesinde bütün çalışanlar bordrolarını Ming üzerinden alabildiği için insan kaynakları operasyonlarını da kolaylaştırıyor.
                                            Bu noktada fuar aracılığıyla Ming’i tanıtarak daha geniş kitlelerin kullanımına sunmak ve şirketlerin insan kaynakları stratejilerine ilham olmak istiyoruz” şeklinde konuştu.</p>

                                    </div>
                                    <div class="post-footer">
                                        <div class="post-tags"><a href="https://www.ekodiplomatik.com/haber/ik-stratejilerine-ilham-veren-ming-uygulamasi-fuara-cikarma-yapacak-37803/" title="yorglass ming" target="_blank">Devamı için Kaynağa Git </a></div>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default News3