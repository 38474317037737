import React, { useEffect, useState } from 'react'
function Header() {

    const [activeLogo, setActiveLogo] = useState('/img/white-logo.png')

    useEffect(() => {
        const navbar = document.querySelector('.navbar');

        // Scroll olayını dinleyin
        window.addEventListener('scroll', function () {
            // Navbar'ın arka plan rengini kontrol edin
            // Scroll olayını dinleyin
            const handleScroll = () => {
                const navbar = document.querySelector('.navbar');
                if (navbar) {
                    // Navbar'ın arka plan rengini kontrol edin
                    const backgroundColor = window.getComputedStyle(navbar).getPropertyValue('background-color');

                    // Arka plan rengine göre logo değişikliğini yapın
                    if (backgroundColor === 'rgb(255, 255, 255)') {
                        // Beyaz arka plan için logo değişikliği yapın
                        setActiveLogo('/img/logo.webp');
                    } else {
                        // Diğer arka plan renkleri için logo değişikliği yapın
                        setActiveLogo('/img/white-logo.png');
                    }
                }
            };

            window.addEventListener('scroll', handleScroll);

            // Cleanup
            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        });

    }, [])


    return (
        <header class="header" id="header">
            <nav class="navbar navbar-expand-lg fixed-top custom-nav white-bg">
                <div class="container">
                    <a class="navbar-brand mobil-w" href="/">
                        <img src={activeLogo} height="54" width="140" alt="logo" class="img-fluid" /></a>
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="ti-menu"></span>
                    </button>

                    <div class="collapse navbar-collapse main-menu" id="navbarSupportedContent">
                        <ul class="navbar-nav ml-auto">

                            <li class="nav-item">
                                <a class="nav-link page-scroll" href="#features">Özellikler</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link page-scroll" href="#events">Etkinliklerimiz</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link page-scroll" href="#benefits">Faydalar</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link page-scroll" href="#modules">Modüller </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link page-scroll" href="#pricing">Paketler</a>
                            </li>
                            {/* <li class="nav-item">
                                <a class="nav-link page-scroll" href="#screenshots">Ekran Görüntüleri</a>
                            </li> */}
                            <li class="nav-item">
                                <a class="nav-link page-scroll" href="#contact" id="contact2">İletişim</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link page-scroll" href="#partner" id="contact2">Partner Ol</a>
                            </li>

                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    )
}

export default Header