import React, { useEffect, useRef, useState } from 'react'
import { sendMail } from '../../events/SendMail'
import { sehirler } from '../../constants/cities'
import ReCAPTCHA from "react-google-recaptcha";
import CheckboxDropdown from '../Items/CheckboxDropdown';
import Select from 'react-select';
import SingleChoiceDropdown from '../Items/SingleChoiceDropdown';

function PartnerForm() {
    const recaptcha = useRef();
    const [companyName, setCompanyName] = useState("")
    const [companyBranch, setCompanyBranch] = useState("")
    const [message, setMessage] = useState("")
    const [selectedCities, setSelectedCities] = useState(null);
    let stringCities = ""
    const [stringCitiesState, setStringCities] = useState("")

    const [name, setName] = useState("")
    const [surname, setSurname] = useState("")
    const [degree, setDegree] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")

    const [sendMailStatus, setSendMailStatus] = useState(0)

    const faaliyetAlani = [
        { label: 'Restoran', value: 'Restoran' },
        { label: 'Gıda', value: 'Gıda' },
        { label: 'Araç', value: 'Araç' },
        { label: 'Giyim', value: 'Giyim' },
        { label: 'Sağlık', value: 'Sağlık' },
        { label: 'Spor', value: 'Spor' },
        { label: 'Çiçek', value: 'Çiçek' },
        { label: 'Veteriner', value: 'Veteriner' },
        { label: 'Otel', value: 'Otel' },
        { label: 'Sanat', value: 'Sanat' },
        { label: 'Diğer', value: 'Diğer' },
    ]
    // <option value="Restoran">Restoran</option>
    //                                         <option value="Eğitim">Eğitim</option>
    //                                         <option value="Gıda">Gıda</option>
    //                                         <option value="Araç">Araç</option>
    //                                         <option value="Giyim">Giyim</option>
    //                                         <option value="Sağlık">Sağlık</option>
    //                                         <option value="Spor">Spor</option>
    //                                         <option value="Çiçek">Çiçek</option>
    //                                         <option value="Veteriner">Veteriner</option>
    //                                         <option value="Otel">Otel</option>
    //                                         <option value="Sanat">Sanat</option>
    //                                         <option value="Diğer">Diğer</option>
    useEffect(() => {
        setName("")
        setSurname("")
        setDegree("")
        setEmail("")
        setPhone("")
        setCompanyName("")
        setCompanyBranch("")
        setSelectedCities(null)
        setMessage("")
    }, [])

    useEffect(() => {
        if (selectedCities === null) {
            return
        }
        else {
            selectedCities.map((item) => {
                stringCities += item + ", "
            })
            setStringCities(stringCities)
        }

    }, [selectedCities])



    const submitMail = async () => {
        const captchaValue = recaptcha.current.getValue();
        if (!captchaValue) {
            setSendMailStatus(4)
        }
        else {
            if (name === "" ||
                surname === "" ||
                email === "" ||
                phone === "" ||
                companyName === "" ||
                companyBranch === "" ||
                selectedCities === null ||
                degree === ""
            ) {
                setSendMailStatus(3)
            }
            else {
                const html = `<div>
            <p>Şirket Adı: ${companyName}</p>
            <p>Şirket Faaliyet Alanı: ${companyBranch.label}</p>
            <p>Faaliyet Gösterilen İller: ${stringCitiesState}</p>
            <p>message: ${message}</p>
            <p>İsim: ${name}</p>
            <p>Soyisim: ${surname}</p>
            <p>Ünvan: ${degree}</p>
            <p>Email: ${email}</p>
            <p>Telefon: ${phone}</p>
        </div>`;

                const subject = companyName + " şirketinden yeni bir partner ol isteği geldi."
                const res = await sendMail(subject, message, html)
                if (res.data.status === 200) {
                    setSendMailStatus(1)
                    setName("")
                    setSurname("")
                    setDegree("")
                    setEmail("")
                    setPhone("")
                    setCompanyName("")
                    setCompanyBranch("")
                    setSelectedCities(null)
                    setMessage("")
                }
                else {
                    setSendMailStatus(2)
                }
            }
        }

    }
    return (
        <section class="contact-us gray-light-bg ptb-100">
            <div class="container pd-t" id="partner">
                <div class="row">
                    <div class="col-12 pb-3 message-box d-none ">
                        <div class="alert alert-danger"></div>
                    </div>
                    <div class="col-md-5">
                        <div class="section-heading font-card">
                            <h4>Ming üzerinden satışlarınızı artırın! Hemen partnerimiz olun.</h4>
                            <p>İndirim ayrıcalıklarını geniş bir kitleye duyurmak ve potansiyel müşterilerinize özel fırsatlar sunmak ister misiniz?
                            </p>
                        </div>
                        <div class="footer-address">
                            <p>
                                Mobil ve web uygulamamız aracılığıyla, indirimli ürünlerinizi ve özel
                                kampanyalarınızı tüm Ming kullanıcılarına ulaştırabilirsiniz. Potansiyel müşterilerinizi ulaşın, satışlarınızı artırın ve
                                markanızı daha da güçlendirin. Sizinle işbirliği yapmak için sabırsızlanıyoruz, hemen başvurun.</p>
                            {/* <span>Telefon: <a href="tel:+905335548612" title="telefon" hreflang="tr"> +90 533 554
                                8612</a></span> <br />
                            <span>Email : <a href="mailto:info@mingapp.co" title="e-mail"
                                hreflang="tr">info@mingapp.co</a></span> */}
                            <img src="/img/rectangle-787.png" style={{ width: "100%" }} />
                        </div>
                    </div>

                    <div class="col-md-7 font-card">
                        <form action="mailSend.php" method="POST" id="contactForm" class="contact-us-form"
                            novalidate="novalidate" autocomplete="on">
                            <h5>Şirket Bilgileri</h5>
                            <div class="row">
                                <div class="col-sm-6 col-12">
                                    <div class="form-group">
                                        <input type="text" class="form-control" name="isim" id="name"
                                            placeholder="Şirket Adı"
                                            value={companyName}
                                            onChange={(e) => setCompanyName(e.target.value)}
                                            required />
                                    </div>
                                </div>
                                <div class="col-sm-6 col-12">
                                    <div class="form-group">
                                        <SingleChoiceDropdown
                                            options={faaliyetAlani}
                                            value={companyBranch}
                                            setValueFunc={setCompanyBranch}
                                            placeholder={'Şirket Faaliyet Alanı'}
                                        />
                                    </div>
                                </div>
                                <div class="col-sm-6 col-6" style={{ textAlign: 'center', width: '100%', marginBottom: 20 }}>
                                    <div class="">
                                        <CheckboxDropdown setSelectedCities={setSelectedCities} />
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <textarea name="mesaj"
                                            value={message}
                                            onChange={(e) => setMessage(e.target.value)}
                                            id="message" class="form-control" rows="7" cols="25"
                                            placeholder="Mesaj" required></textarea>
                                    </div>
                                </div>

                            </div>
                            <h5>İletişim Bilgileri</h5>
                            <div class="row">
                                <div class="col-sm-6 col-12" id="company_hide">
                                    <div class="form-group">
                                        <input type="text" name="sirket" size="40" class="form-control"
                                            onChange={(e) => setName(e.target.value)}
                                            value={name}
                                            id="company" placeholder="Ad" required />
                                    </div>
                                </div>
                                <div class="col-sm-6 col-12" id="company_hide">
                                    <div class="form-group">
                                        <input type="text" name="sirket" size="40" class="form-control"
                                            onChange={(e) => setSurname(e.target.value)}
                                            value={surname}
                                            id="company" placeholder="Soyad" required />
                                    </div>
                                </div>
                                <div class="col-sm-6 col-12" id="company_hide">
                                    <div class="form-group">
                                        <input type="text" name="sirket" size="40" class="form-control"
                                            onChange={(e) => setDegree(e.target.value)}
                                            value={degree}
                                            id="company" placeholder="Ünvan" required />
                                    </div>
                                </div>
                                <div class="col-sm-6 col-12">
                                    <div class="form-group">
                                        <input type="email" class="form-control" name="email" id="email"
                                            placeholder="somename@example.com"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required onblur="validateEmail(this.value);" />
                                    </div>
                                </div>
                                <div class="col-sm-6 col-12">
                                    <div class="form-group">
                                        <input type="tel" name="telefon" class="form-control" id="phone"
                                            onChange={(e) => setPhone(e.target.value)}
                                            value={phone}
                                            maxlength="15" placeholder="(XXX)-XXX-XXXX" required />
                                    </div>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-sm-12 mt-3" >
                                    <div class="g"></div>
                                    <input type="hidden" name="islem" value="gonder" required />
                                    {/* <div class="col-md-12 p-0">
                                        <div id="html_element" class="g-recaptcha d-inline-block"
                                            onChange={(e) => console.log(e)}
                                            data-sitekey="6LcQfS8iAAAAALQCBpszOhF3N8AzMJA0QDZwat22"></div>
                                    </div> */}
                                    <ReCAPTCHA style={{ marginBottom: 20 }} ref={recaptcha} sitekey='6LcQfS8iAAAAALQCBpszOhF3N8AzMJA0QDZwat22' />

                                    <input type="button" id="button" class="btn solid-btn" value="Gönder" onClick={submitMail} />

                                </div>

                            </div>
                        </form><br />

                        {sendMailStatus === 1 && (
                            <div class="alert alert-success">
                                Tebrikler! Mesajınız tarafımıza ulaştı. En kısa sürede size geri
                                dönüş sağlanacaktır.
                                <button
                                    type="button"
                                    class="close"
                                    onClick={() => setSendMailStatus(0)}
                                    data-dismiss="alert"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        )}

                        {sendMailStatus === 2 && (
                            <div class="alert alert-danger" role="alert">
                                Hata! Lütfen email bilgilerinizi kontrol edip tekrar deneyiniz.
                                <button
                                    type="button"
                                    class="close"
                                    onClick={() => setSendMailStatus(0)}
                                    data-dismiss="alert"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        )}

                        {sendMailStatus === 3 && (
                            <div class="alert alert-warning alert-dismissible fade show" role="alert">
                                Dikkat! Alanlar boş bırakılamaz!
                                <button type="button" class="close" onClick={() => setSendMailStatus(0)} data-dismiss="alert" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        )}

                        {sendMailStatus === 4 && (
                            <div class="alert alert-warning alert-dismissible fade show" role="alert">
                                Lütfen robot olmadığınızı doğrulayınız!
                                <button type="button" class="close" onClick={() => setSendMailStatus(0)} data-dismiss="alert" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        )}


                        {/* <?php
             if($_SESSION["isSendMail"] == 1 && $_SESSION["subscribe"]==0){
             echo '<div class="alert alert-success">
                Tebrikler! Mesajınız tarafımıza ulaştı. En kısa sürede size geri dönüş sağlanacaktır.
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
             </div>'; }?>

                <?php
            if($_SESSION["isSendMail"] == 2 && $_SESSION["subscribe"]==0){
            echo '<div class="alert alert-danger" role="alert">
            Hata! Lütfen email bilgilerinizi kontrol edip tekrar deneyiniz.
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
            </div>'; }?>

                <?php
            if($_SESSION["isSendMail"] == 3 && $_SESSION["subscribe"]==0){
            echo '<div class="alert alert-warning alert-dismissible fade show" role="alert">
             Dikkat! Alanlar boş bırakılamaz!
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
             </div>'; }?> */}
                        <p class="form-message"></p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default PartnerForm;
