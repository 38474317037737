import React, { useEffect, useState } from "react";
import { sendMail } from "../../events/SendMail";
import SingleChoiceDropdown from "../Items/SingleChoiceDropdown";

function ContactUsSection() {
  const [nameSurname, setNameSurname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");
  const [paket, setPaket] = useState("");
  const [subject, setSubject] = useState("");

  const [sendMailStatus, setSendMailStatus] = useState(0);

  useEffect(() => {
    setNameSurname("");
    setEmail("");
    setPhone("");
    setCompany("");
    setMessage("");
    setPaket("Basic");
    setSubject("");
  }, []);

  const paketler = [
    { label: 'Basic', value: 'Basic' },
    { label: 'Business', value: 'Business' },
    { label: 'Enterprise', value: 'Enterprise' },
    { label: 'Diğer', value: 'Diğer' },
  ]


  const submitMail = async () => {
    if (
      nameSurname === "" ||
      email === "" ||
      phone === "" ||
      company === "" ||
      message === "" ||
      paket === "" ||
      subject === ""
    ) {
      setSendMailStatus(3);
    } else {
      const html = `<div>
            <p>İsim Soyisim: ${nameSurname}</p>
            <p>Email: ${email}</p>
            <p>Telefon: ${phone}</p>
            <p>Şirket: ${company}</p>
            <p>Paket: ${paket.value}</p>
            <p>Mesaj: ${message}</p>
        </div>`;
      const res = await sendMail(subject, message, html);
      if (res.data.status === 200) {
        setSendMailStatus(1);
        setNameSurname("");
        setEmail("");
        setPhone("");
        setCompany("");
        setMessage("");
        setPaket("Basic");
        setSubject("");
      } else {
        setSendMailStatus(2);
      }
    }
  };

  return (
    <section class="contact-us gray-light-bg ptb-100">
      <div class="container pd-t" id="contact">
        <div class="row">
          <div class="col-12 pb-3 message-box d-none ">
            <div class="alert alert-danger"></div>
          </div>
          <div class="col-md-5">
            <div class="section-heading font-card">
              <h2>Bizimle iletişime geçin</h2>
              <p>
                İletişime geçmenin en kolay yolu.
                <br />
                Formu doldurun size en kısa sürede ulaşalım.
              </p>
            </div>
            <div class="footer-address ">
              <p>Harbiye Mh. Asker Ocağı CD. Süzer Plaza BL. No:6/9 Şişli</p>
              <span>
                Telefon:{" "}
                <a href="tel:+905335548612" title="telefon" hreflang="tr">
                  {" "}
                  +90 533 554 8612
                </a>
              </span>{" "}
              <br />
              <span>
                Email :{" "}
                <a href="mailto:info@mingapp.co" title="e-mail" hreflang="tr">
                  info@mingapp.co
                </a>
              </span>
            </div>
          </div>

          <div class="col-md-7 font-card">
            <form action="mailSend.php" method="POST" id="contactForm" class="contact-us-form"
              novalidate="novalidate" autocomplete="on">
              <h5>Bize Ulaşın</h5>
              <div class="row">
                <div class="col-sm-6 col-12">
                  <div class="form-group">
                    <input type="text" class="form-control" name="isim" id="name"
                      placeholder="İsim Soyisim"
                      value={nameSurname}
                      onChange={(e) => setNameSurname(e.target.value)}
                      required />
                  </div>
                </div>
                <div class="col-sm-6 col-12">
                  <div class="form-group">
                    <input type="email" class="form-control" name="email" id="email"
                      placeholder="somename@example.com"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required onblur="validateEmail(this.value);" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6 col-12">
                  <div class="form-group">
                    <input type="tel" name="telefon" class="form-control" id="phone"
                      onChange={(e) => setPhone(e.target.value)}
                      value={phone}
                      maxlength="15" placeholder="(XXX)-XXX-XXXX" required />
                  </div>
                </div>
                <div class="col-sm-6 col-12" id="company_hide">
                  <div class="form-group">
                    <input type="text" name="sirket" size="40" class="form-control"
                      onChange={(e) => setCompany(e.target.value)}
                      value={company}
                      id="company" placeholder="Şirketiniz" required />
                  </div>
                </div>
                <div class="col-sm-6 col-12">
                  <div class="form-group">
                    <SingleChoiceDropdown
                      placeholder={'Paket Seçiniz'}
                      options={paketler}
                      value={paket}
                      setValueFunc={setPaket}
                    />
                  </div>
                </div>
                <div class="col-sm-6 col-12" id="h_konu">
                  <div class="form-group">
                    <input type="text" name="konu"
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                      size="40" class="form-control" id="konu"
                      placeholder="Konu" required />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <textarea name="mesaj"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      id="message" class="form-control" rows="7" cols="25"
                      placeholder="Mesaj" required></textarea>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 mt-3">
                  <div class="g"></div>
                  <input type="hidden" name="islem" value="gonder" required />


                  <input
                    type="button"
                    id="button"
                    class="btn solid-btn"
                    value="Gönder"
                    onClick={submitMail}
                  />
                </div>
              </div>
            </form>
            <br />

            {sendMailStatus === 1 && (
              <div class="alert alert-success">
                Tebrikler! Mesajınız tarafımıza ulaştı. En kısa sürede size geri
                dönüş sağlanacaktır.
                <button
                  type="button"
                  class="close"
                  onClick={() => setSendMailStatus(0)}
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            )}

            {sendMailStatus === 2 && (
              <div class="alert alert-danger" role="alert">
                Hata! Lütfen email bilgilerinizi kontrol edip tekrar deneyiniz.
                <button
                  type="button"
                  class="close"
                  onClick={() => setSendMailStatus(0)}
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            )}

            {sendMailStatus === 3 && (
              <div
                class="alert alert-warning alert-dismissible fade show"
                role="alert"
              >
                Dikkat! Alanlar boş bırakılamaz!
                <button
                  type="button"
                  class="close"
                  onClick={() => setSendMailStatus(0)}
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            )}

            {/* <?php
             if($_SESSION["isSendMail"] == 1 && $_SESSION["subscribe"]==0){
             echo '<div class="alert alert-success">
                Tebrikler! Mesajınız tarafımıza ulaştı. En kısa sürede size geri dönüş sağlanacaktır.
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
             </div>'; }?>

                <?php
            if($_SESSION["isSendMail"] == 2 && $_SESSION["subscribe"]==0){
            echo '<div class="alert alert-danger" role="alert">
            Hata! Lütfen email bilgilerinizi kontrol edip tekrar deneyiniz.
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
            </div>'; }?>

                <?php
            if($_SESSION["isSendMail"] == 3 && $_SESSION["subscribe"]==0){
            echo '<div class="alert alert-warning alert-dismissible fade show" role="alert">
             Dikkat! Alanlar boş bırakılamaz!
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
             </div>'; }?> */}
            <p class="form-message"></p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactUsSection;
