import axios from "axios"
import { SEND_MAIL } from "../constants/requestApi"
export const sendMail = (subject, text, html) => {
    return axios.post(SEND_MAIL, {
        to: "info@mingapp.co",
        subject: subject,
        text: text,
        html: html
    }).then((response) => {
        return response
    }).catch((error) => {
        console.log(error)
    })
}