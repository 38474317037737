import React from 'react'

function ScreenshotsSection() {
    return (
        <section class="screenshots-section ptb-100 gray-light-bg">
            <div class="container" id="screenshots" >
                <div class="row justify-content-center">
                    <div class="col-md-8">
                        <div class="section-heading text-center pd-t font-card">
                            <h2>Uygulama Ekran Görüntüleri</h2>
                            <p class="lead">Ekran görüntüleri ile Ming’i deneyimleyin.</p>
                        </div>
                    </div>
                </div>
                <div class="screen-slider-content mt-5 screen-shots-content">
                    <div class="screen-carousel owl-carousel owl-theme dot-indicator">
                        <img src="/img/gorev.webp" width="419" height="856" class="img-fluid" alt="görev" />
                        <img src="/img/gorev.webp" width="419" height="856" class="img-fluid" alt="görev" />
                        <img src="/img/hedef.webp" width="419" height="856" class="img-fluid" alt="hedef" />
                        <img src="/img/liderlik.webp" width="419" height="856" class="img-fluid" alt="liderlik" />
                        <img src="/img/akis_yeni.webp" width="419" height="856" class="img-fluid" alt="akış sayfa" />
                        <img src="/img/OKR1.webp" width="419" height="856" class="img-fluid" alt="okr" />
                        <img src="/img/dogum_gunu.webp" width="419" height="856" class="img-fluid" alt="doğum günü" />
                        <img src="/img/is_ilanlari.webp" width="419" height="856" class="img-fluid" alt="iş ilanları" />
                        <img src="/img/oyun.webp" width="419" height="856" class="img-fluid" alt="oyun" />
                        <img src="/img/360_degerlendirme_2.webp" width="419" height="856" class="img-fluid" alt="360 değerlendirme" />
                        <img src="/img/ilan.webp" width="419" height="856" class="img-fluid" alt="ilan" />
                        <img src="/img/gorev_gonderilen.webp" width="419" height="856" class="img-fluid" alt="görev hedef" />
                        <img src="/img/Anket.webp" width="419" height="856" class="img-fluid" alt="anket" />
                        <img src="/img/market_yeni.webp" width="419" height="856" class="img-fluid" alt="market" />
                        <img src="/img/360_degerlendirme_1.webp" width="419" height="856" class="img-fluid" alt="360 değerlendirme" />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ScreenshotsSection