import React from 'react'

function EventsSection() {
    return (
        <section class="promo-section ptb-100">
            <div class="circles">
                <div class="point animated-point-1"></div>
                <div class="point animated-point-2"></div>
                <div class="point animated-point-3"></div>
                <div class="point animated-point-4"></div>
                <div class="point animated-point-5"></div>
                <div class="point animated-point-6"></div>
                <div class="point animated-point-7"></div>
                <div class="point animated-point-8"></div>
                <div class="point animated-point-9"></div>
                <div class="point animated-point-10"></div>
                <div class="point animated-point-11"></div>
                <div class="point animated-point-12"></div>
                <div class="point animated-point-13"></div>
                <div class="point animated-point-14"></div>
                <div class="point animated-point-15"></div>
                <div class="point animated-point-16"></div>
                <div class="point animated-point-17"></div>
                <div class="point animated-point-18"></div>
                <div class="point animated-point-19"></div>
                <div class="point animated-point-20"></div>
            </div>
            <div class="container" id="events">
                <div class="row align-items-center justify-content-between">
                    <div class="col-md-6">
                        <div class="about-content-left section-heading pd-t font-card">
                            <h1> Şirket Kültürünüze Renk Katın
                            </h1>
                            <p>
                                Şirketinizde çalışanların motivasyonunu artırmak ve iş ortamını daha
                                keyifli hale getirmek için gelin birlikte çeşitli etkinlikler düzenleyelim.
                            </p>

                            <div class="single-feature mb-4">
                                <div class="icon-box-wrap mb-2">
                                    <a
                                        href="/events"
                                        class="btn solid-btn2"
                                    >
                                        Detaylar İçin Tıklayın
                                    </a>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="about-content-right resize">
                            <img style={{ borderRadius: 20 }} src="/img/events-banner.jpg" width="870" height="856" alt="Kurumsal bağ ve hafıza gelişimi / Oyunlaştırma ile iş süreçleri iyileştirilmesi" class="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default EventsSection