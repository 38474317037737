import React from 'react'

function Circles() {
    return (
        <div class="circles">
            <div class="point animated-point-1"></div>
            <div class="point animated-point-2"></div>
            <div class="point animated-point-3"></div>
            <div class="point animated-point-4"></div>
            <div class="point animated-point-5"></div>
            <div class="point animated-point-6"></div>
            <div class="point animated-point-7"></div>
            <div class="point animated-point-8"></div>
            <div class="point animated-point-9"></div>
            <div class="point animated-point-10"></div>
            <div class="point animated-point-11"></div>
            <div class="point animated-point-12"></div>
            <div class="point animated-point-13"></div>
            <div class="point animated-point-14"></div>
            <div class="point animated-point-15"></div>
            <div class="point animated-point-16"></div>
            <div class="point animated-point-17"></div>
            <div class="point animated-point-18"></div>
            <div class="point animated-point-19"></div>
            <div class="point animated-point-20"></div>
        </div>
    )
}

export default Circles