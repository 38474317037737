import React, { useEffect } from "react";

export default function Download() {
  useEffect(() => {
    console.log(navigator.userAgent)
    if (/Android/i.test(navigator.userAgent)) {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.ming.mingapp";
    }
    if (/iPad|iPhone|iPod/i.test(navigator.userAgent)) {
      window.location.href = "https://apps.apple.com/tr/app/ming/id1594153351?l=tr";
    }
  }, []);

  return (
    <div class="main">
      <section
        class="hero-section ptb-100 background-img"
        style={{
          background:
            "url('/img/hero_bg_2.webp')no-repeat center center / cover",
        }}
      >
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-8 col-lg-7">
              <div class="page-header-content text-white text-center pt-sm-5 pt-md-5 pt-lg-0"></div>
            </div>
          </div>
        </div>
      </section>
      <div class="module ptb-100">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <article class="post">
                <div class="post-wrapper">
                  <div class="post-content">
                    <p>Yönlendiriliyorsunuz....</p>
                    <br />
                    <div
                      style={{ display: "inline-block", alignSelf: "center" }}
                      class="col-md-12 col-lg-8 mb-md-4 mb-sm-4 mb-lg-0"
                    >
                      <a
                        href="https://play.google.com/store/apps/details?id=com.ming.mingapp&gl=TR"
                        target="_blank"
                        title="Google Play Store"
                      >
                        <img
                          src="img/google_store.webp"
                          alt="Google Play Store"
                          style={{
                            height: "54px",
                            width: "180px",
                            paddingRight: "1px",
                          }}
                        />
                      </a>
                      <a
                        href="https://apps.apple.com/tr/app/ming/id1594153351?l=tr"
                        target="_blank"
                        title="App Store"
                      >
                        <img
                          src="img/app_store.webp"
                          alt="App Store"
                          style={{ height: "54px", width: "180px" }}
                        />
                      </a>
                      <a
                        href="https://appgallery.huawei.com/app/C105495215"
                        target="_blank"
                        title="Huawei App Gallery"
                      >
                        <img
                          src="img/huawei.webp"
                          alt="Huawei App Gallery"
                          style={{ height: "54px", width: "180px" }}
                        />
                      </a>
                      <br />
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
