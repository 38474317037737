import React from 'react'

function VideoPromoSection() {
    return (
        <section class="video-promo ptb-100 background-img"
            style={{ background: "url('/img/hero_bg_1.webp')no-repeat center center / cover" }}>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-6">
                        <div class="video-promo-content mt-4 text-center">
                            <a
                                class="popup-youtube video-play-icon d-inline-block"><span class="ti-control-play"></span></a>
                            <h5 class="mt-4 text-white">Tanıtım videomuz yakında burada olacak..</h5>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default VideoPromoSection